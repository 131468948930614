/* eslint-disable no-nested-ternary */
import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import callIcon from '../../assets/call.png';
import forSurvivorsBySurvivors from '../../assets/for-survivors-by-survivors.png';
import globeIcon from '../../assets/globe.png';
import ledaHealthWhite from '../../assets/leda-health-white.png';
import safeDeliveryIcon from '../../assets/safe_delivery.png';
import settingsIcon from '../../assets/settings.png';
import timelineIcon from '../../assets/time-line.png';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import Loading from '../../Components/Loading';
import NavigationBar from '../../Components/NavigationBar';
import SimpleDialog from '../../Components/SimpleDialog';
import { useStore } from '../../store/index';
import ROUTES from '../../utils/constants/routes';
import log from '../../utils/log';
import { serviceSelector } from '../../utils/selectors';

import StartCard from './StartCard';
import useStyles from './Styles/Home.Style';

// Amplify.configure(awsmobile);

function Home() {
	const [store] = useStore();
	const history = useHistory();
	const { user, isMobile, showLearnMoreCard, userServiceList, userWelcomeContent } = store;
	const classes = useStyles();
	const [enabledServiceList, filterList] = serviceSelector(userServiceList);
	const [selectedService, setSelectedService] = useState(filterList[0]);
	const [openIntroVideo, setOpenIntroVideo] = useState(false);

	const list = Object.keys(enabledServiceList).includes(selectedService)
		? { [selectedService]: enabledServiceList[selectedService] }
		: enabledServiceList;

	useEffect(() => {
		if (user && !user.isSchoolEmailVerified) {
			history.push(ROUTES.ENTER_EMAIL);
		}
	}, [user]);

	log('store', store);

	return user !== null && user !== '' ? (
		<Box className={classes.root}>
			<Header showNavBar showProfileMenu />
			<Box className={classes.body}>
				<StartCard setOpenIntroVideo={setOpenIntroVideo} userWelcomeContent={userWelcomeContent} />

				<>
					{/* <ServiceTabPanel /> */}
					<Box className={classes.homeBox}>
						<Box className={classes.homeBoxContent}>
							<Box className={classes.startBox}>
								<Box className={classes.ledaLogoBox}>
									<img src={ledaHealthWhite} className={classes.ledaLogo} alt="Leda Logo" />
									<img
										src={forSurvivorsBySurvivors}
										className={classes.forSurvivorsBySurvivors}
										alt="For Survivors by Survivors"
									/>
								</Box>
							</Box>
							<Box className={classes.homeBoxTextContent}>
								<Typography className={classes.homeBoxTitle}>
									Welcome <strong>Delta Gamma</strong> to <strong>Leda Health!</strong>
								</Typography>
								<Typography className={classes.resourceContent}>
									At Leda Health, we recognize the critical importance of fostering a safe and
									supportive environment where all Delta Gamma members feel empowered. We are
									dedicated to providing resources and education to address and prevent sexual
									violence within chapters.
								</Typography>
								<div />
								<Typography className={classes.resourceContent}>
									This portal is your gateway to valuable lessons designed to empower, educate, and
									support you in creating a safer and more informed community. Our shared aim is to
									provide resources that foster awareness, prevention, and healing, equipping you
									with the tools needed to navigate challenging situations with confidence and
									compassion.
								</Typography>
								<Typography className={classes.resourceContent}>
									We’re thrilled to have you here as we embark on this important journey together.
									These are the resources we wish we had access to as we started our own journeys.
									started our own journeys. Together, we can make a significant impact in the fight
									against sexual violence.
								</Typography>
								<Typography className={classes.resourceContent}>
									Dive in, explore the content, and let’s make a difference together!
								</Typography>
								<Typography className={classes.resourceContent}>
									Warmly,
									<br />
									<strong>The Leda Health Team</strong>
								</Typography>
							</Box>
						</Box>
					</Box>
					<Box className={classes.homeBox}>
						<Box className={classes.homeBoxNoticeContainer}>
							<Box className={classes.homeBoxNotice}>
								<div className={{ display: 'flex', flexDirection: 'column' }}>
									<Box className={classes.homeBoxNoticeComponent}>
										<div>
											<img
												style={{ width: '40px', paddingTop: '10px' }}
												src={safeDeliveryIcon}
												alt="safe delivery"
											/>
										</div>
										<Typography className={classes.homeBoxNoticeComponentTitle}>
											Visit our online Store!
										</Typography>
									</Box>
									<Box className={classes.homeBoxNoticeComponent}>
										<div>
											<img style={{ width: '40px' }} src={globeIcon} alt="globe" />
										</div>
										<Typography className={classes.homeBoxNoticeComponentText}>
											You can check our online shop for our products such as Drug testing kits and
											Early Evidence Rape Kits.{' '}
											<a href="https://leda.co/delta-gamma-shop">
												<strong>leda.co/delta-gamma-shop</strong>
											</a>
										</Typography>
									</Box>
								</div>
							</Box>

							<Box className={classes.homeBoxNotice}>
								<div className={{ display: 'flex', flexDirection: 'column' }}>
									<Box className={classes.homeBoxNoticeComponent}>
										<div>
											<img
												style={{ width: '40px', paddingTop: '10px' }}
												src={settingsIcon}
												alt="safe delivery"
											/>
										</div>
										<Typography className={classes.homeBoxNoticeComponentTitle}>
											Technical Support
										</Typography>
									</Box>
									<Box className={classes.homeBoxNoticeComponent}>
										<div>
											<img style={{ width: '40px', padding: '5px' }} src={callIcon} alt="globe" />
										</div>
										<Typography className={classes.homeBoxNoticeComponentText}>
											You can get in touch with Leda for technical support or any questions you may
											have at{' '}
											<a href="mailto:support@leda.co">
												<strong>support@leda.co</strong>
											</a>
										</Typography>
									</Box>
									<Box className={classes.homeBoxNoticeComponent}>
										<div>
											<img style={{ width: '40px' }} src={timelineIcon} alt="globe" />
										</div>
										<Typography className={classes.homeBoxNoticeComponentText}>
											You will receive a response within 24 - 48 business hours
										</Typography>
									</Box>
								</div>
							</Box>
						</Box>
					</Box>
				</>
			</Box>
			{isMobile && <NavigationBar />}
			<SimpleDialog
				button={false}
				dialogContentText=""
				dialogTitle=""
				introVideo={userWelcomeContent?.introVideo ? userWelcomeContent?.introVideo : null}
				introBanner={
					userWelcomeContent?.blogLink && userWelcomeContent?.introVideo
						? null
						: !isMobile
						? userWelcomeContent?.blogLink
						: userWelcomeContent?.blogLinkMobile
						? !isMobile
							? userWelcomeContent?.blogLink
							: userWelcomeContent?.blogLinkMobile
						: null
				}
				open={openIntroVideo}
				setOpen={setOpenIntroVideo}
			/>
			<Footer disclaimer />
		</Box>
	) : (
		<Box className={classes.loaderBox}>
			<Loading />
		</Box>
	);
}

export default Home;
